
import { Vue } from "vue-class-component";
import { getService } from "@/service";

export default class Landing extends Vue {
  private h5 = "";
  private pageType = "";

  async created() {
    this.judgeDeviceTypeFunc();
    await this.getUrl();
  }

  async getUrl() {
    const data = await getService().global.downloadUrl();
    this.h5 = this.pageType === "h5" ? data.h5.replace("www", "m") : data.h5;
  }

  // 判断设备类型
  judgeDeviceTypeFunc() {
    const ua = window.navigator.userAgent.toLocaleLowerCase();
    this.pageType = /iphone|ipad|ipod|android/.test(ua) ? "h5" : "pc";
  }

  goHomePage() {
    window.location.href = this.h5;
  }

  goActivityPage() {
    window.location.href = `${this.h5}/activity?type=-1`;
  }

  render() {
    return (
      <div class={`landing ${this.pageType}`}>
        {this.pageType === "h5" ? (
          <div>
            <div class="content-view">
              <img class="bg" src={require(`./assets/bg-h5.png`)} />
              <div class="joinus-btn" onClick={this.goHomePage}></div>
              <div class="pseudo"></div>
            </div>
            <div class="cover">
              <img class="bg" src={require(`./assets/cover.png`)} />
              <div class="bonus-btn" onClick={this.goActivityPage}></div>
            </div>
          </div>
        ) : (
          <div class="content-view">
            <img class="bg" src={require(`./assets/bg-pc.png`)} />
            <div class="content">
              <div class="btn joinus-btn" onClick={this.goHomePage}></div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
